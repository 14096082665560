// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$orange: #dd8130;

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: #FFFFFF;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2em;
  color: #48677c;
  font-weight: 400;
  margin: 0px;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8em;
  color: #48677c;
  font-weight: 400;
  margin: 0px;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
  color: #48677c;
  font-weight: 700;
  margin: 0px;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  color: #7b7b7b;
  margin: 0px;
}

a {
  color: #48677c;
  text-decoration: none;
}

.linkbtn, input.linkbtn, input.submit {
  width: 195px;
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  text-decoration: none;
  background: $orange url(../images/linkbtn-bg.png) right no-repeat;
  padding: 15px 10px;
  border: 0px;
  border-radius: 5px;

  &.lg {
    width: 260px;
    padding: 10px;
    font-size: 14px;
  }

  &.wide {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }

  &.pull-left {
    background: $orange url(../images/linkbtn-bg-flip.png) left no-repeat;
  }

  &.pull-right {
    margin-top: 10px;
    margin-right: 6%;
  }

  &.quote-another {
    background: $orange;
  }
}

input[disabled].submit, input:disabled.submit {
  background: #a1a1a1 url(../images/linkbtn-bg.png) right no-repeat;
}

.linkbtn:hover, input.submit:hover {
  background-color: #333333;
  cursor: pointer;
  color: #FFF;
  text-decoration: none;
}

.linkbtn:active, .linkbtn:focus, input.submit:active, input.submit:focus {
  color: #FFF;
  text-decoration: none;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

div.radiostyle {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #ffffff;
  border: 2px solid #8f8f8f;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}

div.radiostyle.checked {
  background: #ffffff url(../images/radioactive.png) center no-repeat;
}

div.checkboxstyle {
  width: 20px;
  height: 20px;
  float: left;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  vertical-align: middle;
  margin: 4px 10px 0px 0px;
}

div.checkboxstyle.checked {
  background: #ffffff url(../images/checkbox-on.png) center no-repeat;
}

.styled-select {
  width: 100%;
  float: left;
  overflow: hidden;
  background: url(../images/selectdropdown.png) no-repeat right #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.styled-select select {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  color: #5f6364;
  line-height: 1;
  padding: 10px 25px 10px 3%;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.container {
  width: 100%;
  float: left;
  padding: 30px 0px;
}

.header {
  width: 100%;
  float: left;
  text-align: center;
}

.header h1 {
  margin-bottom: 10px;
}

.header p {
  font-size: 1.1em;
  color: #48677c;
}

.windowstyles {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 35px;
}

.windowstyles form, .windowstyles a {
  width: 300px;
  display: inline-block;
  padding: 25px 25px;
  border: 1px solid #d9dada;
  margin: 15px 15px 0px;
  vertical-align: top;
}

.windowstyles form:hover, .windowstyles a:hover {
  background: #eeeeee;
}

.windowstyles form span, .windowstyles a span {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
}

.windowstyles a span.title, .windowstyles a span.title {
  font-size: 1.2em;
  color: #48677c;
}

.windowstyles form span.txt, .windowstyles a span.txt {
  width: 100%;
  min-height: 60px;
  display: inline-block;
  font-size: 0.85em;
  color: #7b7b7b;
}

.windowstyles form span.linkbtn, .windowstyles a span.linkbtn {
  width: 195px;
}

.windowstyles form:hover span.linkbtn, .windowstyles a.hover span.linkbtn {
  background-color: #333333;
}

.designerbox {
  width: 100%;
  /*max-height: 610px;*/
  float: left;
  margin-top: 30px;
  overflow: hidden;
}

.designerboxl {
  width: 49%;
  min-height: 540px;
  float: left;
  background: #e7e8e8;
  padding: 20px 4% 30px;

}

.designerboxl .instructions {
  width: 100%;
  /*height: 490px;*/
  float: left;
  text-align: center;
  margin-bottom: 15px;
}

.designerboxl .instructions p {
  font-size: 0.8em;
  line-height: 1.6em;
}

.designerboxl .instructions p .tip {
  width: 100%;
  display: inline-block;
  font-size: 1.3em;
  color: #48677c;
  margin-bottom: 10px;
}

.designerboxl .prodprice {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.designerboxl .prodprice p {
  font-size: 0.8em;
  color: #7b7b7b;
  text-align: center;
  margin: 0px;
  line-height: 1.8em;
}

.designerboxl .prodprice p span.incvat {
  font-size: 2.6em;
  color: $orange;
}

.designerboxl .prodprice p span.excvat {
  font-size: 1.5em;
}

.designerboxl .navigation {
  width: 100%;
  float: left;
}

.designerboxl .navigation .linkbtn, .designerboxl .navigation input.submit {
  width: 100%;
  padding: 15px 0px;
}

.designerboxl .instructions.measurement img {
  margin: 20px 0;
}

.designerboxl .instructions.windowvisual img {
  margin: 15px 0px 20px;
}

.designerboxr {
  width: 49%;
  min-height: 610px;
  float: right;
}

.designerboxr .designeroptions {
  width: 100%;
  float: right;
  border: 1px solid #d6d7d8;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.designerboxr .designeroptions h2 {
  width: 100%;
  float: left;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  padding: 10px 0px;
  border-bottom: 6px solid $orange;
  margin: 0px;
}

.designerboxr .designeroptionsbox {
  width: 100%;
  float: left;
  background: url(../images/designeroptionsbox-bg.png) top no-repeat;
  padding: 20px 5%;
}

.designerboxr .designeroptionsbox.astragal {
  height: inherit;
  width: 100%;
}

.designerboxr .measurefields p {
  font-size: 0.9em;
  color: #5f6364;
  margin: 0px 0px 20px;
}

.designerboxr .measurefields p span.note {
  width: auto;
  float: right;
  font-size: 0.75em;
  margin-top: 4px;
}

.designerboxr .measurefields input {
  width: 94%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #5f6364;
  background: #f1f1f1 url(../images/measureinput-bg.png) right no-repeat;
  padding: 10px 3%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-top: 5px;
}

.designerboxr input {
  width: 50%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #5f6364;
  background: #f1f1f1 url(../images/measureinput-bg.png) right no-repeat;
  padding: 6px 3%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 5px;
  line-height: 1.2em;

  &.has-error {
    border: 1px solid #a94442;
  }
}

.designerboxr .sashtypefields input, .designerboxr .locationfield input {
  width: 94%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #5f6364;
  padding: 10px 3%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-top: 5px;
  background: #f1f1f1;

  &.has-error {
    border: 1px solid #a94442;
  }
}

.designerboxr .measurefields p.p1 {
  font-size: 1em;
  color: #48677c;
  text-align: center;
}

.designerboxr .measurefields .sashchords {
  width: 100%;
  float: left;
}

.designerboxr .measurefields .sashchords p {
  font-size: 0.8em;
  text-align: left;
  margin: 0px 0px 10px;
}

.designerboxr .measurefields .sashchords label {
  font-size: 1em;
  text-align: left;
  margin: 0px 0px 15px;
  vertical-align: text-bottom;
  font-weight: normal;
}

.designerboxr .measurefields .sashchords label .checkboxstyle {
  margin-top: 0;
}

.designerboxr .measurefields .sashchords .override {
  width: 100%;
  float: left;
}

.designerboxr .measurefields .sashchords .override p {
  width: 48%;
  float: right;
  text-align: left;
  margin: 0px;
}

.designerboxr .measurefields .sashchords .override p span {
  color: #48677c;
  font-weight: 600;
}

.designerboxr .measurefields .sashchords .override a {
  width: 48%;
  float: left;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  background: #48677c;
  padding: 15px 0px;
  border-radius: 5px;
}

.designerboxr .measurefields .sashchords .override a:hover {
  background: #333333;
}

.designerboxr .designeroptionsbox.sashtypefields {
  padding: 20px 5%;
}

.designerboxr .sashtypefields .field {
  width: auto;
  display: inline-block;
  font-size: 1em;
  color: #5f6364;
  margin-right: 35px;
  vertical-align: middle;
}

.designerboxr .sashtypefields .field span {
  font-size: 0.8em;
}

.designerboxr .locationfield p {
  font-size: 0.9em;
  margin: 0px;
}

.designerboxr .locationfield .p1 {
  margin: 0px 0px 10px;
}

.designerboxr .locationfield .styled-select {
  width: 100%;
  float: left;
  overflow: hidden;
  background: url(../images/otheroptionsselect-bg.png) no-repeat right #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.designerboxr .locationfield .styled-select select {
  width: 97%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #48677c;
  line-height: 1.2em;
  padding: 6px 25px 6px 3%;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.designerboxr p.locationfield {
  line-height: 2em;
  margin: 20px 0px 0px;
}

.stickybtns {
  width: 60px;
  position: absolute;
  top: 35%;
  left: 30px;
}

.stickybtns a {
  width: 100%;
  display: inline-block;
  font-size: 0.8em;
  text-align: center;
  margin: 12px 0px;
}

.stickybtns a .icon {
  width: 43px;
  height: 43px;
  display: inline-block;
  background-color: #48677c;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 5px;

  color: $orange;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  padding-left: 2px;
}

.stickybtns a .icon.help {
  background-image: url(../images/stickybtn-help.png);
}

.stickybtns a .icon.restart {
  background-image: url(../images/stickybtn-restart.png);
}

.stickybtns a .icon.flip {
  background-image: url(../images/stickybtn-flip.png);
}

.stickybtns a .icon.mybasket {
  background-image: url(../images/stickybtn-basket.png);
}

.stickybtns a:hover .icon {
  background-color: #333333;
}

.designerboxr .ui-tabs {
  width: 100%;
  float: left;
}

.designerboxr .ui-tabs-nav {
  width: 100%;
  float: right;
  list-style: none;
  padding: 0px;
  border: 1px solid #d8d8d9;
  margin: 0px 0px 20px;
}

.designerboxr .ui-tabs-nav li {
  width: 25%;
  float: left;
  border-right: 1px solid #d8d8d9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.designerboxr .ui-tabs-nav li:last-child {
  border: 0px;
}

.designerboxr .ui-tabs-nav li a {
  width: 100%;
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 12px 0px;
  border-bottom: 6px solid #c1c2c3;
}

.designerboxr .ui-tabs-nav li a span {
  width: 12px;
  height: 6px;
  display: none;
  position: absolute;
  bottom: -12px;
  left: 50%;
  background: url(../images/designeroptionsbox-bg.png) top no-repeat;
  margin-left: -6px;
  z-index: 1;
}

.designerboxr .ui-tabs-nav li.ui-state-active a {
  border-color: $orange;
}

.designerboxr .ui-tabs-nav li.ui-state-active a span {
  display: inline-block;
}

.designerboxr .ui-tabs-panel {
  width: 100%;
  float: right;
}

.designerboxr .ui-tabs-panel p {
  font-size: 1em;
  color: #48677c;
  text-align: center;
}

.designerboxr .optionlist {
  width: 100%;
  max-height: 530px;
  float: left;
  overflow: auto;
  margin-top: 15px;
}

.designerboxr .optionlist a {
  width: 22%;
  display: inline-block;
  position: relative;
  font-size: 0.8em;
  color: #7b7b7b;
  text-align: center;
  margin: 0px 1% 20px;
  vertical-align: top;
}

.designerboxr .optionlist.colorlist a, .designerboxr .optionlist.colorlist-ext a {
  width: 17%;
}

.designerboxr .optionlist a img {
  margin-bottom: 3px;
}

.designerboxr .optionlist.casementstyles a {
  width: 30%;
}

.designerboxr .optionlist.casementstyles a img {
  max-height: 105px;
}

.designerboxr .optionlist a span.selected {
  width: 70%;
  display: none;
  position: absolute;
  top: 60px;
  left: 0px;
  font-size: 0.8em;
  color: #ffffff;
  font-weight: 600;
  background: $orange;
  padding: 5px 0px;
  margin: 0px 15%;
}

.designerboxr .optionlist.colorlist a span.selected, .designerboxr .optionlist.colorlist-ext a span.selected {
  width: 90%;
  margin: 0px 5%;
}

.designerboxr .optionlist a.active img {
  opacity: 0.57;
}

.designerboxr .optionlist.casementstyles a span.selected {
  top: 35px;
}

.designerboxr .optionlist a.active span.selected {
  display: inline-block;
}

.colourtype {
  width: 94%;
  float: left;
  background: #f0f1f1;
  padding: 15px 3% 10px;
  margin-top: 15px;
}

.colourtype p {
  text-align: left !important;
  margin: 0px 0px 10px;
}

.colourtype p span {
  width: auto;
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
}

.colourtype p span label {
  font-weight: normal;
}

.colourtype p .radiostyle {
  margin: 0px 0px 0px 5px;
}

.colourtype p.help {
  font-size: 0.8em;
  font-style: italic;
  text-decoration: underline;
  margin: 0px;
}

.designerboxr .optionlist.colorlist {
  max-height: 405px;
}
.designerboxr .optionlist.colorlist-ext {
  max-height: 240px;
}

.designerboxr .optionlist.colorlist a img, .designerboxr .optionlist.colorlist-ext a img {
  width: 98%;
  padding: 1%;
  border: 1px solid #c1c1c1;
}

.designerboxr .optionlist.colorlist a span.selected, .designerboxr .optionlist.colorlist-ext a span.selected {
  top: 45px;
}

.designerboxr .otheroptions {
  width: 100%;
  float: left;
  background: #f0f1f1;
  overflow: auto;
}

.designerboxr .otheroptionlist, .designerboxr .astragaloptionlist {
  width: 94%;
  float: left;
  padding: 15px 0px;
  border-bottom: 1px solid #ffffff;
  margin: 0px 3%;
}

.designerboxr .astragaloptionlist { width: 100%;}

.designerboxr .glassoptions .otheroptionlist {
  padding: 25px 0px;
}

.designerboxr .otheroptions .otheroptionlist:last-child {
  border: 0px;
}

.designerboxr .otheroptionlist p, .designerboxr .astragaloptionlist p {
  font-size: 0.9em;
  line-height: 2em;
  text-align: left;
  font-weight: bold;
}

.designerboxr .otheroptionlist p label, .designerboxr .astragaloptionlist p label {
  font-weight: normal;
}

.designerboxr .otheroptionlist p.p1, .designerboxr .astragaloptionlist p.p1 {
  width: 100%;
  display: inline-block;
  font-size: 0.8em;
  color: #7b7b7b;
  line-height: 1.6em;
  margin-top: 15px;
}

.designerboxr .otheroptionlist div.radiostyle, .designerboxr .astragaloptionlist div.radiostyle {
  border: 0px;
  box-shadow: 0px 0px 5px 1px #cccccc;
  margin: 0px 25px 0px 10px;
}

.designerboxr .otheroptionlist .styled-select {
  width: 100%;
  float: left;
  overflow: hidden;
  background: url(../images/otheroptionsselect-bg.png) no-repeat right #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.designerboxr .otheroptionlist .styled-select.with-preview {
  width: 85%;
}

.designerboxr .otheroptionlist .styled-select select {
  width: 97%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #48677c;
  line-height: 1.2em;
  padding: 6px 25px 6px 3%;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  font-weight: normal;
}

.imageupload {
  width: 100%;
  float: left;
  font-size: 1.1em;
  color: #48677c;
  text-align: center;
  background: #ffffff;
  padding: 35px 0px;
  border: 2px solid #b8b8b8;
  border-radius: 5px;
  margin: 20px 0px 0px -2px;
}

.imageupload img {
  margin-bottom: 25px;
}

.imageupload span {
  width: auto;
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  background: #333333;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
}

.imageupload span:hover {
  background: #48677c;
}

.designerboxr .designeroptionsbox.yourdetails {
  padding: 18px 5%;
}

.yourdetails input {
  width: 41%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  color: #5f6364;
  background: #f1f1f1;
  padding: 10px 3%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin: 5px 1%;
}

.designerboxl textarea {
  width: 94%;
  height: 75px;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  color: #5f6364;
  background: #ffffff;
  padding: 10px 3%;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  margin: 15px 0%;
}

.basket {
  width: 100%;
  float: left;

  .linkbtn {
    display: block;
    margin: 0 auto;
  }
}

.customerloginbox {

  .linkbtn {
    width: 94%;
    display: block;
    margin: 30px 0 0;
  }
}

.basket h2 {
  width: 100%;
  display: inline-block;
  font-size: 1.6em;
  padding-bottom: 15px;
  border-bottom: 2px solid #de8536;
}



.vieworder {
  width: 100%;
  padding: 20px 3%;
  border: 1px solid #dadadb;
  box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  overflow: auto;

  h2 {
    a {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      vertical-align: bottom;
    }

    span {
      font-size: 1.2em;
      color: #48677c;
      font-weight: 400;
      float: right;
    }
  }
}

.orderitem {
  width: 98%;
  float: left;
  padding: 25px 2% 25px 0px;
  border-bottom: 1px solid #e9e9e9;

  .img {
    width: 25%;
    float: left;
    margin-right: 3%;
  }
}

.breakdown {
  margin: 20px;
  float: right;

  tr {

    th, td {
      padding: 3px 10px;
      font-size: 18px;
      text-align: right;
    }
  }
}

.order-details {
  margin: 30px 20px 0;
  float: left;

  tr {

    th, td {
      padding: 0 10px 5px;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      vertical-align: top;
    }
    th {
    }
  }
}

.specifications {
  margin-top: 20px;
  th, td {
    padding: 0 20px 0 0;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: top;
  }
}

.basketitems {
  width: 61%;
  float: left;
  padding: 20px 3%;
  border: 1px solid #dadadb;
  box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
}

.basketitemsscroll {
  width: 100%;
  /*max-height: 330px;*/
  float: left;
  overflow: auto;
}

.basketitem {
  width: 98%;
  float: left;
  padding: 25px 2% 25px 0px;
  border-bottom: 1px solid #e9e9e9;
}

.basketitem .img {
  width: 17%;
  float: left;
  margin-right: 5%;
}

.basketitem .img img {
  width: 100%;
  float: left;
  background: #f6f6f6;
}

.basketitem .delete {
  width: auto;
  float: right;
  font-size: 1.1em;
}

.basketitem .delete a {
  width: auto;
  display: inline-block;
  padding: 5px 25px 5px 0px;
  border-right: 1px solid #dadada;
  margin-right: 21px;
}

.basketitem .delete a:last-child {
  padding: 5px 0px;
  border: 0px;
  margin: 0px;
}

.basketitem p {
  font-size: 1.1em;
  color: #48677c;
  margin: 15px 0px 0px;
}

.basketitem.addanother {
  width: 100%;
  padding-right: 0px;
  border: 0px;
  margin-bottom: 25px;
}

.basketitem.addanother a {
  width: 100%;
  display: inline-block;
  font-size: 1.1em;
  text-align: center;
  background: #f6f6f6;
  padding: 10px 0px;
  border: 1px solid #d9dada;
  box-shadow: inset 0px 0px 15px 0px #cccccc;
}

.basketitem.addanother a span {
  width: auto;
  display: inline-block;
  margin-left: 20px;
}

.basketitem.addanother a:hover {
  color: #ffffff;
  background: #333333;
  box-shadow: none;
}

.basketinstallation {
  width: 96%;
  float: left;
  text-align: center;
  padding: 15px 2%;
  border: 1px solid #dadadb;
}

.basketinstallation img {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.basketinstallation .txt {
  width: auto;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin: 0px 15px;
}

.basketinstallation .txt p {
  font-size: 0.8em;
  color: #48677c;
}

.basketinstallation a {
  width: auto;
  display: inline-block;
  font-size: 0.9em;
  color: #ffffff;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  background: #bababa;
  padding: 15px 30px;
  border-radius: 3px;
}

.basketinstallation a:hover {
  background: #333333;
}

.basketitems {
  .styled-select {
    width: 106px;
    float: right;
    overflow: hidden;
    background: url(../images/basketselect-bg.png) no-repeat right #ffffff;
    border: 1px solid #dadada;
    border-radius: 0px;
    margin-top: 10px;
  }
  span.styled-select {
    width: 106px;
    float: right;
    overflow: hidden;
    background: url(../images/basketselect-bg.png) no-repeat right #ffffff;
    border: 1px solid #dadada;
    border-radius: 0px;
    margin-top:0;
  }
}

.basketitems .styled-select select {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #48677c;
  line-height: 1;
  padding: 6px 25px 6px 20px;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.basketitems .extras {
  width: 99%;
  float: left;
  margin: 0px;
  border-bottom: 1px solid #e9e9e9;
}

.basketitems .extras td {
  color: #48677c;
  vertical-align: bottom;
  padding: 25px 0px;
}

.basketitems .extras tr td:last-child {
  text-align: right;
}

.basketitems .extras strong {
  font-size: 1.4em;
}

.basketitems .extras a {
  color: #de8536;
}

.basketitems .extras .checkboxstyle {
  width: 32px;
  height: 32px;
  float: none;
  display: inline-block;
  border: 1px solid #dddddd;
  margin-left: 10px;
  vertical-align: middle;
  margin-top: 0px;
}

.basketitems .extras .checkboxstyle.checked {
  background: url(../images/checkbox-on.png) center no-repeat;
}

.basketprice {
  width: 31%;
  float: right;
  padding: 20px 3%;
  border: 1px solid #dadadb;
}

.basketprice table {
  margin: 20px 0px;
}

.basketprice table td {
  font-size: 1em;
  color: #48677c;
  padding: 5px 0 0;
}

.basketprice table td:first-child {
  padding-right: 20px;
}

.basketprice table td.total {
  font-size: 1.1em;
}

.basketprice p.vouchercode {
  font-size: 1em;
  color: #48677c;
  margin: 0px 0px 30px;
}

.basketprice p.vouchercode input {
  width: 94%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  color: #5f6364;
  padding: 10px 3%;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-top: 3px;
}

.basketprice p.cardsaccepted {
  width: 100%;
  display: inline-block;
  font-size: 1.2em;
  color: #48677c;
  padding-top: 25px;
  border-top: 1px solid #e9e9e9;
  margin: 0px 0px 30px;
}

.basketprice p.cardsaccepted img {
  margin-top: 10px;
}

.basketprice p.termsaccept, .basketprice label.termsaccept {
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.basketprice p.termsaccept a, .basketprice label.termsaccept a {
  width: auto;
  display: inline;
  color: #de8536;
  padding: 0px;
  background: none;
  border-radius: 0px;
  font-size: 14px;
}

.basketprice input.sashed-btn.addbasket {
  width: 100%;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  background: #00be79 url(../images/checkoutbtn-bg.png) right no-repeat;
  padding: 15px 0px;
  border-radius: 5px;
  border: none;
}

.basketprice input.sashed-btn.addbasket:hover {
  background-color: #333333;
}

.basketprice .savequote {
  width: 100%;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  padding: 0px;
  border: 0px;
  margin: 20px 0px 0px;
}

.basketprice a.sashed-btn, .basketprice input[type=submit] {
  width: 100%;
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background: #de8536 url(../images/checkoutbtn-bg.png) right no-repeat;
  padding: 15px 0px;
  border-radius: 5px;
}

.trash-txt {
  font-size: 1.1em;
  color: #48677c;
}

a.trash-btn {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background: #de8536;
  padding: 10px 15px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background: #333333;
  }
}

.back-btn {
  width: 100%;
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  background: #de8536 url(../images/linkbtn-bg-flip.png) left no-repeat;
  padding: 12px 0px;
  border-radius: 5px;

  &:hover {
    background-color: #333333;
    color: #fff;
    text-decoration: none;
  }
}


.basketprice a.sashed-btn:hover, .basketprice input[type=submit]:hover {
  background-color: #333333;
}

.savequotebox {
  width: 94%;
  float: left;
  padding: 20px 3% 40px;
  border: 1px solid #dadadb;
}

.savequotebox h1 {
  width: 100%;
  display: inline-block;
  font-size: 1.6em;
  padding-bottom: 15px;
  border-bottom: 2px solid #de8536;
  margin-bottom: 45px;
}

.savequoteboxl {
  width: 42%;
  float: left;
  padding: 10px 3%;
}

.savequoteboxl table {
  width: 100%;
}

.savequoteboxl td {
  color: #48677c;
  line-height: 1.6em;
  vertical-align: middle;
}

.savequoteboxl td span {
  width: 100%;
  display: inline-block;
  font-size: 1.2em;
  padding-bottom: 3px;
}

.savequoteboxl td.img {
  width: 65px;
}

.savequoteboxl td.space {
  padding: 15px 0px;
}

.savequoteboxr {
  width: 46%;
  float: right;
  padding: 10px 0px 0px 4%;
  border-left: 1px solid #eaeaea;
}

.savequoteboxr p.input {
  font-size: 1.2em;
  color: #48677c;
  font-weight: 700;
  margin: 0px 0px 30px;
}

.savequoteboxr p.input input {
  width: 94%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  color: #5f6364;
  padding: 15px 3%;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-top: 10px;
}

.savequoteboxr .submit {
  font-size: 1em;
  text-align: right;
}

.savequoteboxr .submit input {
  width: 75%;
  display: inline-block;
  font-size: 1em;
  color: #ffffff;
  text-align: center;
  background: #00be79 url(../images/checkoutbtn-bg.png) right no-repeat;
  padding: 15px 0px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.savequoteboxr .submit input:hover {
  background-color: #333333;
}

.checkoutprocess {
  width: 100%;
  float: left;
  text-align: center;
  background: url(../images/checkoutprocess-bg.jpg) 0px 13px repeat-x;
  margin-bottom: 30px;
}

.checkoutprocess div {
  width: 30%;
  display: inline-block;
  color: #de8536;
  font-weight: 600;
}

.checkoutprocess div:first-child {
  width: auto;
  float: left;
}

.checkoutprocess div:last-child {
  width: auto;
  float: right;
}

.checkoutprocess img {
  margin-bottom: 5px;
}

.summaryitems {
  background: #fbfbfb;
}

.basketitem .itemdetails {
  width: 75%;
  float: right;
}

.summaryitems .itemdetails {
  width: 75%;
  display: inline-block;
  vertical-align: top;

  &.closed {
    display: none;
  }
}

.summaryitems .itemdetails p, .summaryitems .itemdetails td, .summaryitems .itemdetails th {
  font-size: 0.9em;
  line-height: 1.6em;
  margin: 0;
  color: #48677c;
  font-family: 'Open Sans', sans-serif;
}

.summaryitems .itemdetails th {
  padding-right: 20px;
}
.summaryitems .itemdetails tr {
  border-bottom: 1px solid #eeeeee;

  &:last-child{
    border-bottom: none;
  }
}

.summaryitems .itemdetails p.sub-header {
  font-size: 1.1em;

  span {
    float: right;
  }

  &.btm {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

.summaryitems .itemdetails h3 span {
  float: right;
  font-weight: normal;
}

.summaryitems .itemprice {
  width: 100px;
  float: right;
  font-size: 1em;
  color: #48677c;
  font-weight: 600;
}

.gotoaccount {
  width: 100%;
  float: left;

  a.customerdetailsbox {
    width: 30%;
    float: left;
    background: #fbfbfb;
    padding: 15px 45px;
    border: 1px solid #dadadb;
    margin: 15px 10% 0;

    &:hover {
      background: #eeeeee;
    }

    img {
      float: left;
      height: 50px;
      width: 50px;
      margin: 0 30px 0 0;
    }

    h3 {
      float: left;
      line-height: 50px;
    }

    p {
      text-align: center;
      margin-top: 5px;
      font-weight: normal;
      font-size: 1em;
    }

    a.linkbtn {
      display: block;
      margin: 45px auto;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.guestorlogin {
  width: 100%;
  float: left;

  .customerloginbox {
    width: 40%;
    float: left;
    background: #fbfbfb;
    padding: 30px 5% 30px;
    border: 1px solid #dadadb;
    margin: 45px 5%;
    text-align: left;

    &:hover {
      background: #eeeeee;
    }

    h3 {
      text-align: center;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 2px solid #de8536;
    }

    p {
      margin-top: 15px;
      font-weight: normal;
      font-size: 1em;
    }

    a.linkbtn {
      display: block;
      float: right;
      margin: 45px 0 15px;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .customerdetailsbox {
    width: 40%;
    float: left;
    background: #fbfbfb;
    padding: 45px 5% 30px;
    border: 1px solid #dadadb;
    margin: 45px 5%;

    &:hover {
      background: #eeeeee;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    h3 {
      text-align: center;
      margin-top: 60px;
    }

    p {
      text-align: center;
      margin-top: 5px;
      font-weight: normal;
      font-size: 1em;
    }

    a.linkbtn {
      display: block;
      margin: 45px auto;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.customerdetails {
  width: 50%;
  float: left;
}

.customerdetailsbox {
  width: 90%;
  margin: 0 auto;
  background: #fbfbfb;
  padding: 20px 5%;
  border: 1px solid #dadadb;
  margin-bottom: 30px;
  min-height: 570px;

  &.small {
    min-height: 0;
  }

  p span select {
    height: 42px;
    background: white;
    margin-top: 4px;

    option {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.8em;
      color: #5f6364;
    }
  }
}

.customerdetailsbox2 {
  width: 90%;
  margin: 0 auto;
  background: #fbfbfb;
  padding: 20px 5%;
  border: 1px solid #dadadb;
  margin-bottom: 30px;
  min-height: 570px;

  p {
    font-size: 1.1em;
    color: #48677c;
    font-weight: 700;
    margin: 20px 0px 0px;

    &.instructions {
      font-size: 1em;
      color: #5f6364;
      font-weight: normal;
      margin: 10px 0 0;
    }
  }

  input {
    width: 94%;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    color: #5f6364;
    padding: 7px 3%;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 3px;
    margin-top: 5px;
  }

  div.line {
    width: 100%;
    height: 1px;
    display: inline-block;
    background: #e9e9e9;
    margin: 30px 0px 0px;
  }

  div.field {
    margin-top: 20px;

    label {
      margin-right: 30px;
    }
  }
}

#collection_details {
  display: none;
}

.customerdetailsbox h2 {
  margin-bottom: 10px;
}

.customerdetailsbox p, .customerloginbox p {
  font-size: 1.2em;
  color: #48677c;
  font-weight: 700;
  margin: 20px 0px 0px;
  span {

    color: #5f6364;
  }
}

.customerdetailsbox p sup {
  color: #CC0000;
}

.customerdetailsbox input:not([type="submit"]), .customerloginbox input[type=text], .customerloginbox input[type=password] {
  width: 94%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  color: #5f6364;
  padding: 10px 3%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 3px;
  margin-top: 5px;
}

.customerloginbox input {
  width: 100%;
}

.customerdetailsbox div.line {
  width: 100%;
  height: 1px;
  display: inline-block;
  background: #e9e9e9;
  margin: 30px 0px 0px;
}

.orderconfirmationbox {
  width: 90%;
  float: left;
  background: #fbfbfb;
  padding: 20px 5%;
  border: 1px solid #dadadb;
  margin-top: 30px;
}

.orderconfirmationbox.ordernumber {
  padding: 10px 5% 15px;
  margin-top: 0px;
}

.orderconfirmationbox h1 {
  font-size: 1.8em;
  text-align: center;
}

.orderconfirmationbox h1 span {
  color: #de8536;
}

.orderconfirmationbox h1 span.error {
  color: #d42f2f;
}

.orderconfirmationbox table {
  margin-top: 25px;
}

.orderconfirmationbox td {
  font-size: 1em;
  color: #48677c;
  line-height: 1.6em;
  padding-right: 20px;
}

.orderconfirmationbox p {
  font-size: 1em;
  color: #48677c;
  line-height: 1.6em;
  margin: 25px 0px 0px;
}

.orderconfirmationbox .confirmationitem {
  width: 100%;
  float: left;
  padding-bottom: 25px;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 25px;
}

.orderconfirmationbox .confirmationitem .price {
  width: 130px;
  float: right;
  font-size: 1em;
  color: #48677c;
  font-weight: 600;
  margin-top: 5px;
}

.orderconfirmationbox .confirmationitem p {
  line-height: 1.8em;
  margin-top: 5px;
}

.basketprice p.confirmationinfo {
  width: 100%;
  display: inline-block;
  font-size: 1.4em;
  color: #48677c;
  line-height: 1.6em;
  padding-top: 25px;
  border-top: 1px solid #e9e9e9;
  margin: 0px 0px 30px;
}

.basketprice p.backtosite {
  width: 100%;
  display: inline-block;
  font-size: 1.4em;
  color: #48677c;
  line-height: 1.6em;
  margin: 0px;
}

.basketprice p.backtosite a {
  width: auto;
  color: #de8536;
  background: none;
  padding: 0px;
  border-radius: 0px;
}

.basketprice a.tryagain {
  background-color: #00be79;
}

.basketprice a.tryagain:hover {
  background-color: #333333;
}

.basketitem .sizenote {
  width: 100%;
  display: inline-block;
  font-size: 0.9em;
  margin: 0px;
}

#pricebox {
  top: 0;
  left: 67%;
  background-color: #f0f1f1;
  position:fixed;
  top:0;
  width:25%;
  z-index:100;
  border-top: 30px solid #f5f8fa;
}

.window-colour-option {
  width: 83px;
  height: 83px;
  border: 1px solid #c1c1c1;
  margin-bottom: 3px;
}

.designerboxr .otheroptionlist p.p1.obscure-different, .designerboxr .otheroptionlist p.p1.obscure-none, .designerboxr .measurefields p.p1.dimensions-warning {
  color: $orange;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

#trim-measurements {
  margin-top: 40px;

  p {
    padding-left: 10px;
  }
}

#preview-image, #preview-bars {
  max-width: 300px;
  max-height: 350px;
}

.btn-default.preview {
  height: 29px;
  float: right;
}

.preview {
  margin-left: 10px;
}

a.window-style img {
  max-height: 90px;
}

.modal-body {
  height: 500px;
  overflow-y: scroll;

  p {
    margin: 0 10px 20px;
  }
}

.astragal-icon {
  border: 1px solid #c1c1c1;
  margin-bottom: 10px;
}

.astragal-select {
  text-align: center;
  width: percentage(1/9);
  float: left;
  padding-right: 1%;

  img {
    width: 100%;
  }

  .radiostyle {
    margin: 0 auto 10px !important;
  }
}

span.astragal-number{
  background-color: $orange;
  border-radius: 50%;
  color: #FFF;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  padding-left: 1px;
}

p.show-spec {
  width: 75%;
  float: right;
  margin: 10px 0;
  color: $orange;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

li, a, select, textarea, input, button { outline: none !important; }

.user-nav {
  width: 100%;
  margin: 15px 0;
  padding: 0;

  ul {
    width: 100%;
    background: #FAA55A;
    margin: 0;

    li {
      list-style:none;
      display: inline-block;

      &:last-child {
        float: right;
      }

      a {
        display: block;
        color: #FFF;
        text-decoration: none;
        padding: 0 15px;
        cursor: pointer;
        line-height: 30px;
        height: 100%;

        &:hover {
          background: $orange;
          text-decoration: underline;
        }
      }
    }
  }
}

fieldset.divider {
  border-top: 1px solid #d9dada;
  border-bottom: none;
  border-left: none;
  border-right: none;
  display: block;
  text-align: center;
  margin: 30px 9% 15px;
  width: 82%;

  legend {
    padding: 5px 10px;
    margin: 0;
    width: auto;
    border: none;
    color: #48677c;
  }
}

.alert-custom {
  margin-bottom: 0;
  margin-top: 15px;
}

/* USER ACCOUNT AREAS */

.dashboard {
  width: 100%;
  float: left;
}

.dashboardleft {
  width: 27%;
  float: left;
}

.dashboardleft .welcome {
  width: 90%;
  float: left;
  background: #eaeaea;
  padding: 20px 5%;
}

.dashboardleft .welcome p {
  font-size: 0.9em;
  color: #100f09;
  line-height: 1.4em;
}

.dashboardleft .welcome p.p1 {
  font-size: 1.2em;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.dashboardleft .welcome p.p1 img {
  float: left;
  margin: 10px 10px 10px 0px;
}

.dashboardleft .welcome a {
  color: #100f09;
}

.dashboardleft .dashboardnav {
  width: 100%;
  float: left;
}

.dashboardleft .dashboardnav a {
  width: 90%;
  display: inline-block;
  font-size: 0.9em;
  color: #ffffff;
  background-color: $orange;
  background-position: right;
  background-repeat: no-repeat;
  padding: 20px 5%;
  margin-top: 1px;
}

.dashboardleft .dashboardnav a:hover {
  background-color: #333333;
}

.dashboardright {
  width: 71%;
  float: right;
}

.dashboardright a {
  width: 100%;
  float: left;
  background: #65655d url(../../images/dashboarddesigner-bg.jpg);
  background-size: cover;
  background-position: bottom;
  border: 1px solid #cfcfcf;
}

.dashboardright a .top {
  width: 94%;
  float: right;
  font-size: 2.6em;
  color: $orange;
  line-height: 1.2em;
  font-weight: bold;
  text-align: right;
  padding: 20px 3% 200px;
  text-shadow: #404040 0px 0px 10px;
}

.dashboardright a .bot {
  width: 100%;
  float: right;
  color: #12110d;
  text-align: right;
  background: #ffffff;
  padding: 25px 3%;
  border-top: 1px solid #cfcfcf;
}

.dashboardright a:hover .bot {
  background: #cccccc;
}

.ordersearch {
  width: 100%;
  float: left;
  text-align: right;
  background: #e6eaed;
  padding: 10px 3%;
  border: 1px solid #cccccc;
  margin-bottom: 25px;
}

.ordersearch input.input {
  display: inline-block;
  font-family: "Open Sans";
  font-size: 0.9em;
  color: #80746d;
  padding: 5px 15px;
  border: 1px solid #cccccc;
  vertical-align: middle;
}

.ordersearch input.submit, .ordersearch .addnew a {
  width: auto;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  color: #ffffff;
  background: #f9b437;
  background: -webkit-linear-gradient(#ffca69, #f39d01);
  background: -o-linear-gradient(#ffca69, #f39d01);
  background: -moz-linear-gradient(#ffca69, #f39d01);
  background: linear-gradient(#ffca69, #f39d01);
  padding: 5px 15px;
  border: 0px;
  border-radius: 5px;
  margin: 0px 0px 0px 5px;
  vertical-align: middle;
}

.ordersearch input.submit:hover, .ordersearch .addnew a:hover {
  background: #333333;
}

.ordersearch .addnew {
  width: auto;
  float: left;
}

.backbtn {
  margin: 0px 0px 15px;
}

.backbtn a {
  color: #12110d;
}

.cmscontent {
  width: 100%;
  float: left;
}

.cmscontent h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.8em;
  color: #12110d;
  font-weight: 400;
  text-align: center;
  margin: 0px 0px 25px;
}

.orderdetails .detailsbox {
  width: 94%;
  float: left;
  border: 1px solid #cccccc;
  margin: 25px 3% 0px;
}

.orderdetails .detailsbox h2 {
  width: 94%;
  float: left;
  font-size: 1.2em;
  color: #ffffff;
  font-weight: bold;
  background: #f39d00;
  padding: 10px 3%;
  border-bottom: 1px solid #c5c5c5;
  margin: 0px;
}

.userdetails {
  width: 100%;
  float: left;
}

.userdetails .formbox {
  width: 29.333333%;
  float: left;
  margin: 0px 2%;
}

.userdetails h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
  color: #12110d;
  font-weight: 600;
  margin: 0px 0px 15px;
}

.userdetails table {
  width: 100%;
}

.userdetails td {
  font-size: 0.9em;
  padding: 5px 0px;
  width: 100%;
}

.userdetails .input {

  input, select {
    width: 94%;
    font-family: "Open Sans";
    font-size: 0.9em;
    color: #80746d;
    padding: 10px 3%;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
  }

  select {
    height: 42px;
  }
}

.userdetails .submit {
  text-align: right;
}

.userdetails .submit input, .userdetails .submit a {
  width: auto;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #ffffff;
  text-transform: uppercase;
  background: #f9b437;
  background: -webkit-linear-gradient(#ffca69, #f39d01);
  background: -o-linear-gradient(#ffca69, #f39d01);
  background: -moz-linear-gradient(#ffca69, #f39d01);
  background: linear-gradient(#ffca69, #f39d01);
  padding: 10px 35px;
  border: 0px;
  border-radius: 5px;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 5px;
}

.userdetails .submit input:hover, .userdetails .submit a:hover {
  background: #333333;
}

.accountstbl {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #cccccc;
}

.accountstbl a {
  color: #f39d00;
}

.accountstbl th {
  font-size: 0.9em;
  color: #ffffff;
  text-align: left;
  font-weight: 600;
  background: #f39d00;
  padding: 10px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.accountstbl td {
  font-size: 0.9em;
  text-align: left;
  padding: 5px 10px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.accountstbl td.prodimg {
  text-align: center;
}

.accountstbl td.prodimg img {
  height: 96px;
}

.accountstbl .tr2 {
  background: #e6eaed;
}

.accountstbl .links {
  width: 80px;
  text-align: center;
  vertical-align: top;
}

.accountstbl .links a {
  width: auto;
  display: inline-block;
  color: #ffffff;
  background: #f9b437;
  background: -webkit-linear-gradient(#ffca69, #f39d01);
  background: -o-linear-gradient(#ffca69, #f39d01);
  background: -moz-linear-gradient(#ffca69, #f39d01);
  background: linear-gradient(#ffca69, #f39d01);
  padding: 10px;
  border-radius: 5px;
  margin: 0;
  width: 80px;
}

.accountstbl .links a:hover {
  background: #333333;
  text-decoration: none;
}

#sliding_matrix {
  margin: 0 auto;

  tr {
    th {
      padding: 0 5px;
    }
    td {
      padding: 4px;

      input {
        width: 70px;
      }
    }
  }
}

#hinged_matrix {
   margin: 0 auto;

   tr {
     th {
       padding: 0 3px;
     }
     td {
       padding: 2px;

       input {
         font-size: 12px;
         line-height: 14px;
         width: 50px;
       }
     }
   }
 }

.addnew {
  margin: 15px 5px;
}

.orange-bg {
  background-color: $orange !important;

  .panel-title a {
    color: #FFF;

    &:hover {
      color: #FFF;
    }
  }
}

.header-left ul.nav-menu {
  margin-top: 15px;
}

.header-left .headcontact {
  height: 42px;
  padding-top: 0px;
}

.label-helper {
  font-weight: normal;

  span {
    font-weight: bold;
  }
}

#actual-size {
  display: none;
  margin: 0;

  .fa {
    color: $orange;
  }
}

ul.text-list {
  margin-left: 10px;

  li {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #7b7b7b;
  }
}

.opening-guide {
  margin: 20px 0 0;
}

.call-you {
  padding-top: 10px;
  color: #48677c;
}

p.delivery-notice {
  margin-bottom: 15px;
  font-size: 14px;
  color: #48677c;
  font-weight: normal;

  span {
    font-weight: bold;
  }
}

span.bars-number {
  background-color: $orange;
  border-radius: 50%;
  color: #FFF;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0 0;
  padding-left: 10px;
}

.orange {
  color: $orange !important;
}

.lightbox-image {
  width: 60%;
  margin: 20px 20%;
}

.lightbox-style {
  display: block;
  height: 255px;
  width: auto;
  margin: 0 auto;
}

.lightbox-image-label {
  text-align: center;
  margin-bottom: 30px;
}

.middle-box {
  width: 400px;
  margin: 0 auto;
}

.mar-btm-xs {
  margin-bottom: 5px;
}

.mar-btm-sm {
  margin-bottom: 10px;
}

.mar-btm-md {
  margin-bottom: 20px;
}

.no-mar {
  margin: 0 !important;
}

.no-padding {
  padding: 0;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #bcbcbd;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #BCBCBD;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #BCBCBD;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #BCBCBD;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color:    #BCBCBD;
}